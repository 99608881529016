/*
 * Contents
 *
 * Global resets
 * Images
 * Sidebar
 * Container
 * Reverse layout
 * Themes
 * Scrollbar
 */

/*
 * Global resets
 *
 * Update the foundational and global aspects of the page.
 */

html {
  font-family: "B612", Helvetica, Arial, sans-serif;
}

body {
  color: #848482;
  background-color: #171c1a;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'B612', serif;
  color: #ffcb9a;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-family: 'B612', serif;
  color: #50585d;
  font-kerning: auto;
  font-feature-settings: "kern", "liga", "dlig", "hlig", "cswh";
  font-synthesis: weight style;
}

a {
  color: #1a9799;
  transition: color ease 0.3s;
}

a:hover {
  color: #51dfe1;
}

.btn {
  display: inline-block;
  padding: .25rem 1rem;
  font-family: "B612", sans-serif;
  color: #ebcb8b;
  border: .1rem solid #32343c;
  border-radius: .1rem;
}

.btn:hover,
.btn:focus {
  color: #26282d;
  background-color: #ebcb8b;
  border-color: #ebcb8b;
}

strong,
b {
  color: #848471;
}
/* Code */

code {
  color: #d1e8e2;
  white-space: pre-wrap;
  background-color: #072b2c;
}

pre {
  background-color: #072b2c;
}

pre {
  white-space: pre;
  overflow: auto !important;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: rgba(235, 242, 246, 0.29) 1px solid;
  margin: 1.2em 0;
  padding: 0;
}

blockquote {
  color: #666;
  border-left: .25rem solid #333;
}

td,
th {
  padding: .25rem .5rem;
  border: 1px solid #333;
}

tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: #d1e8e2;
}

/* Pagination items can be `span`s or `a`s */
.pagination-item {
  border: 1px solid #444;
}

@media (min-width: 48em) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 58em) {
  html {
    font-size: 20px;
  }
}

/*
 * Messages
 *
 * Show alert messages to users. You may add it to single elements like a `<p>`,
 * or to a parent if there are multiple elements to show.
 */

.message {
  color: #bbb;
  background-color: #333;
}

/* Gist */
.gist-data {
  background-color: #d1e8e2 !important;
  border-bottom: 1px solid #333 !important;
}
.gist .markdown-body {
  color: #999 !important;
}

.gist .markdown-body pre {
  background-color: #111 !important;
}

.gist-file {
  border: 1px solid #333 !important;
}

.gist-meta {
  background-color: #d1e8e2 !important;
}

/*
 * Images
 */

.rimg {
  float: right;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.limg {
  float: left;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.cimg {
  margin: 0 auto;
  display: block;
}

/*
 * Sidebar
 *
 * Flexible banner for housing site name, intro, and "footer" content. Starts
 * out above content in mobile and later moves to the side with wider viewports.
 */

.sidebar {
  text-align: center;
  padding: 2rem 1rem;
  color: rgba(255, 255, 255, .5);
  background-color: #171c1a;
  overflow: auto;
}
@media (min-width: 48em) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 18rem;
    text-align: left;
  }
}

/* Sidebar links */
.sidebar a {
  color: #c68853;
}

/* About section */
.sidebar-about h1 {
  color: #c68853;
  margin-top: 0;
  font-family: 'Noto Serif', serif;
  font-size: 2.25rem;
}

/* Sidebar nav */
.sidebar-nav {
  margin-bottom: 1rem;
}
.sidebar-nav-item {
  display: block;
  line-height: 1.75;
}
a.sidebar-nav-item:hover,
a.sidebar-nav-item:focus {
  text-decoration: underline;
}
.sidebar-nav-item.active {
  font-weight: bold;
  color: #ffcb9a;
}

/* Sticky sidebar
 *
 * Add the `sidebar-sticky` class to the sidebar's container to affix it the
 * contents to the bottom of the sidebar in tablets and up.
 */

@media (min-width: 48em) {
  .sidebar-sticky {
    /*    position: absolute;*/
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
}

/* Container
 *
 * Align the contents of the site above the proper threshold with some margin-fu
 * with a 25%-wide `.sidebar`.
 */

.content {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (min-width: 48em) {
  .content {
    max-width: 95%;
    margin-left: 18rem;
    margin-right: 0rem;
  }
}

@media (min-width: 64em) {
  .content {
    margin-left: 18rem;
    margin-right: 0rem;
  }
}

/*
 * Posts and pages
 *
 * Each post is wrapped in `.post` and is used on default and post layouts. Each
 * page is wrapped in `.page` and is only used on the page layout.
 */

/* Blog post or page title */
.page-title,
.post-title,
.post-title a {
  color: #c68853;
}

/*
 * Reverse layout
 *
 * Flip the orientation of the page by placing the `.sidebar` on the right.
 */

@media (min-width: 48em) {
  .layout-reverse .sidebar {
    left: auto;
    right: 0;
  }
  .layout-reverse .content {
    margin-left: 2rem;
    margin-right: 20rem;
  }
}

@media (min-width: 64em) {
  .layout-reverse .content {
    margin-left: 4rem;
    margin-right: 22rem;
  }
}

/* Thin Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-button {
  width: 8px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #333;
  border: thin solid #111;
  /*box-shadow: 0px 0px 3px #111 inset;*/
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #d1e8e2;
  border: thin solid #d1e8e2;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
}
